import debounce from 'debounce';
import mixpanel from 'mixpanel-browser';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { OrderRfqOrigin, OrderToDisplay } from '../../types/order.types';
import { trackingEvents } from '../../context/Tracking';
import { parseOrderStatus } from '../../context/utils/orderStatusParser';
import { putCommentToWholeEditableOrder } from '../../db/editableOrders';
import Button from '../Button/Button';
import ChatBubble from '../ChatBubble/ChatBubble';
import styles from './OrderSummaryRemarksTab.module.css';
import { OrderStatus } from '../../apiClient/generated';
import { GarretsContactTooltip } from '../Tooltips/GarretsContactTooltip';
import { useOrder } from '../../hooks/useOrder';
import moment from 'moment';

interface ChatMessage {
    id?: number;
    sender: {
        displayName: string;
        avatarUrl: string;
    };
    createdAt?: Date;
    message: string | undefined;
    position?: 'start' | 'end';
}

interface OrderSummaryRemarksTabProps {
    orderToDisplay: OrderToDisplay | undefined;
}

const OrderSummaryRemarksTab: React.FC<OrderSummaryRemarksTabProps> = ({
    orderToDisplay,
}) => {
    const { isLocked } = parseOrderStatus(orderToDisplay);

    const [chatMessage, setChatMessage] = useState('');
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [wrapperMinHeight, setWrapperMinHeight] = useState(400);
    const [isEditing, setIsEditing] = useState(false);
    const [editMessageIndex, setEditMessageIndex] = useState<number | null>(
        null,
    );
    const [editText, setEditText] = useState<string | undefined>('');
    const [isAddingComment, setIsAddingComment] = useState(true);

    const wrapperRef = useRef<HTMLElement>(null);
    const listRef = useRef<HTMLElement>(null);

    const { data: order } = useOrder(orderToDisplay?.orderId);
    const sortedOrder =
        order &&
        [...order.rfqs].sort((a, b) =>
            moment(b.created).diff(moment(a.created)),
        );

    const isRemarksTabLocked = orderToDisplay?.isSending ?? isLocked;

    useEffect(() => {
        const messages: ChatMessage[] =
            order?.rfqs
                ?.filter((rfq) => rfq.comment)
                ?.map((rfq) => ({
                    id: rfq?.id,
                    createdAt: rfq?.created,
                    message: rfq?.comment ?? '',
                    sender: {
                        displayName:
                            rfq?.origin === OrderRfqOrigin.WristIntegration
                                ? 'Garrets'
                                : 'You',
                        avatarUrl:
                            rfq?.origin === OrderRfqOrigin.WristIntegration
                                ? '/gfx/generateLogo.svg'
                                : '',
                    },
                    position:
                        rfq?.origin === OrderRfqOrigin.WristIntegration
                            ? 'end'
                            : 'start',
                })) ?? [];

        const isDraftOrReview = 
        orderToDisplay && orderToDisplay.status === OrderStatus.Blank;
        const isLastMessageUnique =
            orderToDisplay?.rfq.comment &&
            orderToDisplay?.rfq.comment !== undefined &&
            sortedOrder &&
            orderToDisplay?.rfq.comment !== sortedOrder[0]?.comment;
        const isRemarksEmpty =
            orderToDisplay?.rfq.comment &&
            orderToDisplay?.rfq.comment !== undefined &&
            messages.length === 0;

        // Checks whether last comment from order.rfqs and orderToDisplay are
        // not the same or if there are no existing comments yet, then push
        // the current comment from orderToDisplay data.
        // This is to avoid the duplication of latest comment since
        // orderToDisplay.rfq always contains the latest rfq data from order.rfqs
        if (isDraftOrReview && (isLastMessageUnique || isRemarksEmpty)) {
            messages.push({
                createdAt: orderToDisplay?.rfq.created,
                message: orderToDisplay?.rfq.comment ?? '',
                sender: {
                    displayName: 'You',
                    avatarUrl: '',
                },
                position: 'start',
            });
        }

        setMessages(messages);
        setIsAddingComment(
            messages.length === 0 ||
                (sortedOrder !== undefined &&
                    sortedOrder[0]?.origin ===
                        OrderRfqOrigin.WristIntegration &&
                    messages[messages.length - 1].id !== undefined),
        );
    }, [order, isRemarksTabLocked, orderToDisplay]);

    // Sets the current comment of orderToDisplay to undefined if
    // the comment from orderToDisplay and last comment from Garrets
    // are the same. This is to prevent duplication of the comment when
    // submitting the order with no attached comment from the vessel
    useEffect(() => {
        if (
            orderToDisplay &&
            orderToDisplay?.rfq?.comment ===
                order?.rfqs[order.rfqs.length - 1]?.comment &&
            orderToDisplay.rfq.origin !== OrderRfqOrigin.App
        ) {
            putCommentToWholeEditableOrder(orderToDisplay, undefined);
        }
    }, []);

    useEffect(() => {
        // Makes sure the chat container has the right size
        // There doesn't seem to be a preferrable solution available via CSS
        if (wrapperRef?.current) {
            const onResize = () => {
                const offsetTop =
                    wrapperRef?.current?.getBoundingClientRect?.()?.top;
                if (
                    offsetTop &&
                    typeof window !== 'undefined' &&
                    !isNaN(window.innerHeight)
                ) {
                    setWrapperMinHeight(window.innerHeight - offsetTop);
                }
            };
            onResize();

            window.addEventListener('resize', debounce(onResize, 100));

            return () => window.removeEventListener('resize', onResize);
        }
    }, [wrapperRef]);

    const scrollToLatestMessage = (smooth?: boolean) => {
        if (listRef?.current) {
            // Scroll chat to bottom on initial load
            listRef.current.scroll({
                top:
                    listRef.current.scrollHeight - listRef.current.clientHeight,
                behavior: smooth ? 'smooth' : 'auto',
            });
        }
    };

    useEffect(scrollToLatestMessage, [listRef]);
    useEffect(() => scrollToLatestMessage(true), [messages?.length]);

    const addComment = async (e: FormEvent<HTMLFormElement>) => {
        if (!orderToDisplay) return;

        const currentMessages = messages;

        e.preventDefault();
        const message: ChatMessage = {
            createdAt: orderToDisplay?.rfq.created,
            message: chatMessage,
            sender: {
                displayName: 'You',
                avatarUrl: '',
            },
            position: 'start',
        };

        currentMessages.push(message);

        setChatMessage('');
        setMessages(currentMessages);
        setIsAddingComment(false);

        putCommentToWholeEditableOrder(orderToDisplay, message.message);

        mixpanel.track(trackingEvents.remarkOrderAdded, {
            orderId: orderToDisplay?.orderId,
        });
    };

    const startEditing = (index: number) => {
        setIsEditing(true);
        setEditMessageIndex(index);
        setEditText(messages[index].message);
    };

    const saveEdit = () => {
        if (editMessageIndex !== null) {
            const updatedMessages = [...messages];
            updatedMessages[editMessageIndex].message =
                editText === '' ? undefined : editText;
            setMessages(updatedMessages);
            setIsEditing(false);
            setEditMessageIndex(null);
            setEditText('');

            // Immediately save the edited comment
            if (orderToDisplay) {
                putCommentToWholeEditableOrder(
                    orderToDisplay,
                    editText === '' ? undefined : editText,
                );
            }
        }
    };

    const cancelEdit = () => {
        setIsEditing(false);
        setEditMessageIndex(null);
        setEditText('');
    };

    const isOrderForReview = orderToDisplay?.status === OrderStatus.OrderForReview;

    return (
        <section ref={wrapperRef} className={styles.remarksWrapper}>
            {messages.length > 0 && (
                <section
                    className={`${
                        !isRemarksTabLocked
                            ? styles.chatMessagesListWithCommentSection
                            : ''
                    } ${styles.chatMessagesList} ${styles.scroll}`}
                    ref={listRef}
                >
                    {messages.map((message, index) => {
                        // comment is editable only for the last comment made by the user
                        const isCommentEditable =
                            !isRemarksTabLocked &&
                            !isEditing &&
                            !isAddingComment &&
                            index === messages.length - 1;

                        return (
                            <div key={index} className={styles.messageWrapper}>
                                <ChatBubble
                                    sender={message.sender}
                                    createdAt={message.createdAt}
                                    message={message.message}
                                    position={message.position}
                                />
                                <div
                                    className={
                                        index === messages.length - 1 &&
                                        isEditing
                                            ? styles.messageActionsEditing
                                            : styles.messageActions
                                    }
                                >
                                    {isCommentEditable && (
                                        <Button
                                            text="Edit Comment"
                                            smallRounded
                                            onClick={() => startEditing(index)}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </section>
            )}
            {isEditing && (
                <section className={styles.editeditor}>
                    <div>
                        <textarea
                            className={styles.editmessageInput}
                            rows={2}
                            name="editMessage"
                            placeholder="Edit your message..."
                            onChange={(e) => setEditText(e.target?.value)}
                            value={
                                editText && editText.length > 200
                                    ? editText.slice(0, 200)
                                    : editText
                            }
                            maxLength={200}
                        ></textarea>
                        <div
                            style={{
                                fontSize: '12px',
                                textAlign: 'right',
                                marginRight: '25px',
                            }}
                        >
                            <span
                                style={
                                    editText && editText?.length >= 200
                                        ? { color: 'red' }
                                        : { color: 'black' }
                                }
                            >
                                {editText && editText?.length > 200
                                    ? '200'
                                    : editText?.length ?? '0'}
                                /200
                            </span>
                        </div>
                    </div>
                    <span className={styles.editButtonWrapper}>
                        <Button
                            text="Save"
                            smallRounded
                            onClick={saveEdit}
                            disabled={!!editText && editText?.length > 200}
                        />
                        <Button
                            text="Cancel"
                            smallRounded
                            onClick={cancelEdit}
                        />
                    </span>
                </section>
            )}
            {!isOrderForReview&& !isEditing && !isRemarksTabLocked && isAddingComment && (
                <div>
                    <div className={styles.noRemarksContainer}>
                        {messages.length === 0 && (
                            <p className={styles.noRemarksText}>
                                This order has no remarks yet
                            </p>
                        )}
                        <p
                            className={`${styles.noRemarksText} ${styles.noRemarksTextFaded}`}
                        >
                            Use the input field below to attach remarks to your
                            order, before submitting it to Garrets
                        </p>
                    </div>
                    <section className={styles.editor}>
                        <form onSubmit={addComment}>
                            <div>
                                <textarea
                                    className={styles.messageInput}
                                    rows={2}
                                    name="message"
                                    placeholder="Type your message..."
                                    onChange={(e) =>
                                        setChatMessage(e.target?.value)
                                    }
                                    value={chatMessage}
                                    maxLength={200}
                                ></textarea>
                                <div
                                    style={{
                                        fontSize: '12px',
                                        textAlign: 'right',
                                    }}
                                >
                                    <span
                                        style={
                                            chatMessage?.length === 200
                                                ? { color: 'red' }
                                                : { color: 'black' }
                                        }
                                    >
                                        {chatMessage?.length ?? '0'}/200
                                    </span>
                                </div>
                            </div>
                            <span className={styles.sendButtonWrapper}>
                                <Button
                                    text="Add comment"
                                    smallRounded
                                    disabled={!chatMessage.length}
                                />
                            </span>
                        </form>
                    </section>
                </div>
            )}
            {order?.status === OrderStatus.Receipt && (
                <div className={styles.noRemarksContainer}>
                    <p className={styles.noRemarksText}>Order was completed</p>
                    <p
                        className={`${styles.noRemarksText} ${styles.noRemarksTextFaded}`}
                    >
                        If you have a question about this order or need help,
                        <br /> please reach out to{' '}
                        <GarretsContactTooltip>
                            <span className={styles.noRemarksTextBlue}>
                                {' '}
                                your Garrets contact
                            </span>
                        </GarretsContactTooltip>
                    </p>
                </div>
            )}
        </section>
    );
};

export default OrderSummaryRemarksTab;
